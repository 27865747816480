<template>
  <v-chip
    :text-color="statusTextColor(isPublish)"
    :color="statusFillColor(isPublish)"
    dark
  >
    {{ statusName(isPublish) }}
  </v-chip>
</template>

<script>
export default {
  name: 'PublishStatusChip',
  props: {
    isPublish: {type: Boolean}
  },
  methods: {
    statusTextColor(isPublish) {
      if (isPublish) return 'info800Color'
      else return 'disableTextColor'
    },
    statusFillColor(isPublish) {
      if (isPublish) return 'info200Color'
      else return 'failChip'
    },
    statusName(isPublish) {
      if (isPublish) return 'Publish'
      else return 'Unpublish'
    },
  }
}
</script>
