<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">
          <span t-data="headline" class="orange--text">Confirmation</span>
          <v-icon t-data="headline-icon" color="orange">information</v-icon>
        </v-card-title>
        <v-card-text t-data="card-text">
          This content will be changed to
          <span
            :style="{
              color: status === isActiveText ? 'green' : 'red',
              fontSize: '16px'
            }"
            >{{ status }}</span
          >
          <br />Are you sure you want to change?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn t-data="change-btn" color="red darken-1" text @click="comfirmPopup">Change</v-btn>
          <v-btn t-data="cancel-btn" color="black darken-1" text @click="close">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'UpdateStatusPopup',
  props: {
    show: {type: Boolean},
    close: {type: Function},
    confirm: {type: Function},
    status: {type: String},
    isActiveText: {type: String, default: 'Active'}
  },
  methods: {
    closePopup() {
      this.close()
    },
    comfirmPopup() {
      this.confirm()
    }
  }
}
</script>

<style scoped>
</style>