<template>
  <filter-button
    :count-filtered="countFiltered"
    :disabled-clear="!isClearFilter"
    :disabled-apply="!isChangeFilter"
    @cancel="handleCancelFilter"
    @clear="handleClearFilter"
    @apply="handleSearchFilter"
  >
    <template v-slot:content>
      <v-list-item class="ml-5 mr-5 fixWidth d-block">
        <v-multiple-selected
          t-data="selector-filter"
          title="Branch"
          placeholder="All Branches"
          :branch="orgList.all"
          :selectedBranch="orgList.selected"
          :syncData="syncListSelectedData"
          :rule="() => true"
          :multiple="true"
          :is-changed="isChanged"
          :is-disabled="hasOneOrg"
          @resetIsChanged="isChanged = false"
        ></v-multiple-selected>
      </v-list-item>

      <v-list-item class="mt-2 ml-5 mr-5 fixWidth d-block">
        <p
          class="filterContentTitle sub-body-bold mb-0"
          t-data="department-title"
        >
          Department
        </p>
        <v-select
          class="can-disable-selector selector-filter"
          t-data="department-selector"
          placeholder="Select Department"
          :items="departmentList"
          outlined
          required
          color="primary500Color"
          dense
          v-model="departmentSelected"
          item-text="name"
          item-value="id"
          :disabled="departmentList.length <= 0"
        ></v-select>
      </v-list-item>

      <v-list-item
        class="mt-2 ml-5 mr-5 fixWidth d-block"
        t-data="order-channel-filter"
      >
        <p class="mb-0 filterContentTitle sub-body-bold">Channel</p>
        <v-select
          class="selector-filter"
          t-data="channel-selector"
          placeholder="All Channels"
          :items="channelList"
          outlined
          required
          color="primary500Color"
          dense
          v-model="channelSelected"
          item-text="label"
          item-value="value"
        ></v-select>
      </v-list-item>

      <v-list-item class="ml-2 mr-2 mt-2">
        <div class="d-flex flex-column justify-start align-start">
          <p
            class="mb-0 filterContentTitle sub-body-bold"
            t-data="promotion-title"
          >
            Promotion
          </p>
          <template v-for="(item, i) in promotionItems">
            <v-checkbox
              t-data="promotion-checkbox"
              color="secondary"
              :key="i"
              v-model="promotionSelected"
              :label="item.text"
              :value="item.value"
              class="mt-0 filterContent body-regular"
              hide-details
            ></v-checkbox>
          </template>
        </div>
      </v-list-item>
    </template>
  </filter-button>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import MultipleSelected from '../../components/MultipleSelected.vue'
import FilterButton from '../../components/FilterButton'
import {convertSelectedData} from '@/helper/helper'
import {displayTypeText} from '@/constants/package'
var _ = require('lodash')

export default {
  name: 'FilterDropdown',
  data: () => ({
    channelSelected: null,
    appliedChannel: null,
    promotionItems: [{text: 'Discount Package', value: '1'}],
    promotionSelected: [],
    departmentSelected: null,
    countFiltered: 0,
    isChanged: false,
    appliedBranches: [],
    appliedPromotions: [],
    appliedDepartments: null
  }),
  components: {
    FilterButton,
    'v-multiple-selected': MultipleSelected
  },
  computed: {
    categoryItems() {
      return this.$store.state.news.category
    },
    isClearFilter() {
      const changedChannel = !_.isEqual(this.channelSelected, null)
      const changedPromotion = !_.isEqual(this.promotionSelected, [])
      const changedDepartment = !_.isEqual(this.departmentSelected, null)
      const changedBranch = !_.isEqual(this.orgList.selected, [])
      if (this.hasOneOrg) return changedChannel || changedPromotion || changedDepartment
      return changedChannel || changedPromotion || changedDepartment || changedBranch
    },
    isChangeFilter() {
      const changedChannel = !_.isEqual(
        this.channelSelected,
        this.appliedChannel
      )
      const changedPromotion = !_.isEqual(
        this.promotionSelected,
        this.appliedPromotions
      )
      const changedDepartment = !_.isEqual(
        this.departmentSelected,
        this.appliedDepartments
      )
      const changedBranch = !_.isEqual(
        this.orgList.selected,
        this.appliedBranches
      )
      if (this.hasOneOrg) return changedChannel || changedPromotion || changedDepartment
      return changedChannel || changedPromotion || changedDepartment || changedBranch
    },
    hasOneOrg() {
      return this.orgList.all.length === 1
    },
    channelList() {
      const types = [{label: 'All Channels', value: ''}]
      for (let key in displayTypeText) {
        if (displayTypeText.hasOwnProperty(key)) {
          types.push({label: displayTypeText[key], value: key})
        }
      }
      return types
    },
    ...mapState('packages', ['orgList', 'departmentList'])
  },
  watch: {
    departmentList() {
      if (this.departmentList.length === 0) {
        this.clearDepartmentSelected()
      }
    }
  },
  methods: {
    clearDepartmentSelected() {
      this.departmentSelected = null
    },
    sumFiltered() {
      const sumChannel = this.channelSelected === null ? 0 : 1
      const sumBranch =
        this.hasOneOrg || this.orgList.selected.length <= 0 ? 0 : 1
      const sumDepartment = this.departmentSelected === null ? 0 : 1
      return this.promotionSelected.length + sumBranch + sumDepartment + sumChannel
    },
    syncListSelectedData(data) {
      const allBranch = data !== null && data.length === 0
      this.clearDepartmentSelected()
      this.resetDepartmentList()
      this.orgList.selected = []
      this.orgList.selected = convertSelectedData(
        data,
        this.orgList.all,
        this.orgList.selected
      )
      if (!allBranch) {
        this.fetchDepartment(data)
      }
    },
    handleSearchFilter() {
      this.$emit('apply')
      this.appliedChannel = this.channelSelected
      this.appliedBranches = this.orgList.selected
      this.appliedPromotions = this.promotionSelected
      this.appliedDepartments = this.departmentSelected
      this.setFilterChannel(this.channelSelected)
      this.setFilterPromotion(this.promotionSelected)
      this.setFilterDepartment(this.departmentSelected)
      this.fetch()
      this.countFiltered = this.sumFiltered()
    },
    handleClearFilter() {
      if (!this.hasOneOrg) this.orgList.selected = []
      this.channelSelected = null
      this.promotionSelected = []
      this.departmentSelected = null
      this.isChanged = true
    },
    handleCancelFilter() {
      if (!this.hasOneOrg) {
        this.orgList.selected = this.appliedBranches
        this.isChanged = true
      }
      // wait for multiple select render
      this.$nextTick(() => {
        this.channelSelected = this.appliedChannel
        this.promotionSelected = this.appliedPromotions
        this.departmentSelected = this.appliedDepartments
        this.setFilterChannel(this.channelSelected)
        this.setFilterPromotion(this.promotionSelected)
        this.setFilterDepartment(this.departmentSelected)
        this.countFiltered = this.sumFiltered()
      })
    },
    ...mapActions('packages', [
      'fetchDepartment',
      'fetch',
      'setFilterDepartment',
      'setFilterChannel',
      'setFilterPromotion',
      'resetDepartmentList'
    ])
  }
}
</script>
